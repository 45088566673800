import React from "react"
import { graphql, Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Industries.module.css"
import * as Icons from "components/Icons"
import HubspotForm from "components/HubspotForm"
import blogStyles from "./BlogPost.module.css"
import Blocks from "components/Blocks"

const icons = {
  linkedin: Icons.Linkedin,
  twitter: Icons.Twitter,
  facebook: Icons.Facebook,
  github: Icons.Github,
}

// eslint-disable-next-line
export const IndustriesPage = ({ data, socials, html, casestudies, blogPosts, ...props }) => (
  <Layout {...props} {...data} {...blogPosts}>
    {data.layout === 'old' ? (
      <div>
        <Container className={blogStyles.post}>
          <h2>{data.title}</h2>
          <div className={blogStyles.html} dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
        {data.form ? (
          <HubspotForm id={data.form} portalId={data.portalId} />
        ) : null}

        <Container className={cx(styles.landingWrap, blogStyles.socials)}>
          {Object.keys(socials).map((social) => (
            <a key={social} href={socials[social]} target="_blank" rel="noopener noreferrer">
              {icons[social]}
            </a>
          ))}
        </Container>
      </div>
    ) :
      <div>
        <Container className={styles.wrapper}>
            {data.bannerTitle ? (
               <div>
                <Title className={styles.bannertitle}>{data.title}</Title>
                <p className={`${styles.title} ${styles.titlesmall}`}>
                <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || ""}} />
                </p>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
                <Link className={styles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
              </div>
            ) : 
            <div>
              <Title className={styles.title}>
                <span>{data.title}</span>
              </Title>
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
            </div>
             }
          {data.image ? (
            <div className={styles.image}>
              {data.image.extension === "svg" ? (
                <img alt={data.bannerImageAltText ? data.bannerImageAltText : data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="560" height="420"/>
              ) : data.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={data.bannerImageAltText ? data.bannerImageAltText : data.name}
                  title={data.name}
                  fluid={data.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
        </Container>

        {(data.blocks || []).map((block, i) => {
          const Elem = Blocks[block.type]
          return Elem ? <Elem key={i} {...block} {...blogPosts} casestudies={casestudies}/> : null }
        )}

        {data.form && data.portalId ? (
          <div className={styles.grayBackground} id="contact-form">
            <Container>
              <div className={styles.titleContainer}>
                <TitleBlock isLarger><span className={styles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
              </div>
              <HubspotForm id={data.form} portalId={data.portalId} />
            </Container>
          </div>
        ) : null}
      </div>
    }
  </Layout>
)

// eslint-disable-next-line
export const IndustriesPageWrapper = ({ data, ...props }) => (
  <IndustriesPage 
    {...props}
    socials={data.socials.frontmatter.socials}
    html={data.markdownRemark.html}
    data={data.markdownRemark.frontmatter}
    casestudies={data.casestudies.edges}
    blogPosts={data.blogPosts}
  />
)

export default IndustriesPageWrapper

export const pageQuery = graphql`
  query IndustriesPage($id: String) {
    socials: markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
      frontmatter {
        socials {
          linkedin
          twitter
          facebook
          github
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        layout
        id
        bannerTitle
        title
        description
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        category
        bannerImageAltText
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        blocks {
          type
          title
          secondTitle
          thirdTitle
          description
          buttonText
          buttonUrl
          buttonPosition
          subTitle
          layout
          bgColor
          imagePosition
          imageWidth
          paddingTop
          
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          bigListList {
            title
            description
          }
          gridWithImageList {
            title
            description
          }
          imageAndListList
          gridWithIconsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            link
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          bigListWithBackgroundsList {
            title
            description
          }
          gridWithImageAndList {
            title
            description
          }
          cards {
            title
            text
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          gridWithLeftedImage {
            title
            text
          }
          gradientedGridList {
            title
            text
          }
          twoCollGridList {
            title
            text
          }
          iconsWithBackgroundsList {
            title
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          listWithImageAndDescription {
            title
            description
          }
          gridWithIconsAndImageList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          partners {
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithImage {
            title
            subtitle
            text
            buttonText
            buttonUrl
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithCards {
            title
            preTitle
            description
            text
            buttonText
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          tableData {
            tableHead {
              label
            }
            tableRow {
              row {
                value
                icon
              }
            }
          }
          fullWidthCardsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          blobIcons {
            title
            link
            description
            imageAltText
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          chessGridList {
            title
            description
            link
            imageAltText
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          iconsPathList {
            title
            description
            icon {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          fullWidthImageGrid {
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
    casestudies: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "successstories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            mainTitle
            date(formatString: "MMM Do, YYYY")
            quote
            logo {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
